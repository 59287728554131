<template>
    <div>
        <title-bar :title="$t('navigations.mainForms')"/>
        <advanced-filter
            :fields-array="filterFields"
            :action="showFilterModal"
            @clearAction="showFilterModal = false"
            @paramsChange="setAdvancedParams"
        />
        <b-row>
            <b-col sm="3">
                <b-card header="Submit a form">
                    <b-list-group id="types-list">
                        <b-list-group-item
                            v-for="(item, index) in formTypes"
                            :key="index"
                            :href="'/forms/add/' + item.numberId"
                            class="d-flex justify-content-between align-items-center"
                        >
                            {{ item.label }}
                        </b-list-group-item>
                    </b-list-group>
                </b-card>
            </b-col>
            <b-col sm="9">
                <div v-if="rows" class="custom-table">
                    <table-actions
                        :actions="['search', 'perPage', 'advancedFilter']"
                        @onSearchInput="inputChange"
                        @perPageChange="perPageChange"
                        @enabledChange="enabledChange"
                        @filterChange="showFilterModal = true"
                        :defaultPerPage="perPage"
                    />
                    <table-lite
                        :is-slot-mode="true"
                        :is-loading="isLoading"
                        :columns="columns"
                        :rows="rows"
                        :sortable="sortable"
                        @do-search="setSort"
                        @is-finished="isLoading = false"
                    >
                        <template v-slot:createdAt="data">
                            {{ $helper.formatDate(data.value.createdAt) }}
                            {{ $helper.formatTime(data.value.createdAt) }}
                        </template>
                        <template v-slot:type="data">
                            {{ data.value.type.name }}
                        </template>
                        <template v-slot:employee="data">
              <span v-if="data.value.employee">
                {{
                      data.value.employee.givenName +
                      " " +
                      data.value.employee.familyName
                  }}
              </span>
                        </template>
                        <template v-slot:station="data">
              <span v-if="data.value.station">
                {{ data.value.station.name }}
              </span>
                        </template>
                        <template v-slot:actions="data">
                            <div class="just-buttons">
                                <view-btn :path="$helper.getViewUrl('forms', data.value.id)"/>
                            </div>
                        </template>
                    </table-lite>
                    <table-pagination
                        v-model="currentPage"
                        :total="pages"
                        :per-page="perPage"
                        @input="onChangePage"
                    />
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import TableActions from "@/components/Table/TableActions.vue";
import ViewBtn from "@/components/Buttons/ViewBtn.vue";
import ResourceUtils from "@/mixins/ResourceUtils";
import ListUtils from "@/mixins/ListUtils";
import AdvancedFilter from "@/components/Table/AdvancedFilter.vue";
import {getUserData} from "@/auth/utils";

export default {
    name: "FormListing",
    components: {
        TableLite,
        TablePagination,
        TableActions,
        ViewBtn,
        TitleBar,
        AdvancedFilter,
    },
    mixins: [ResourceUtils, ListUtils],
    data() {
        return {
            search: "",
            isLoading: false,
            rows: null,
            perPage: this.$store.state.defaultPerPage,
            currentPage: 1,
            pages: 0,
            showColumnsModal: false,
            showFilterModal: false,
            enabled: true,
            formTypes: [],
            sortable: {
                order: "createdAt",
                sort: "desc",
            },
            filterFields: [
                {
                    label: "forms.formType",
                    name: "type",
                    value: null,
                    type: "entity",
                    entityName: "$FormTypes",
                },
                {
                    label: "forms.station",
                    name: "station",
                    value: null,
                    type: "entity",
                    entityName: "$Stations",
                },
            ],
            advancedParams: [],
            columns: [
                {
                    label: this.$t("forms.createdAt"),
                    field: "createdAt",
                    sortable: true,
                },
                {
                    label: this.$t("forms.type"),
                    field: "type",
                    sortable: true,
                },
                {
                    label: this.$t("forms.submittedBy"),
                    field: "employee",
                    sortable: true,
                },
                {
                    label: this.$t("forms.station"),
                    field: "station",
                    sortable: true,
                },
                {
                    label: this.$t("forms.actions"),
                    field: "actions",
                    sortable: false,
                    width: "80px",
                },
            ],
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            this.formTypes = []
            this.getResourceList(this.$FormTypes, this.formTypes);
            const params = {
                itemsPerPage: this.perPage,
                page: this.currentPage,
            };
            params["order[" + this.sortable.order + "]"] = this.sortable.sort;
            if (this.search && this.search.length) {
                params.search = this.search;
            }
            if(this.$helper.userAdditionalRole(process.env.VUE_APP_ROLE_ENGINEER)) {
                params['station'] = getUserData()['station']['id']
            }
            this.advancedParams.forEach((element) => {
                if (element.value !== "" && element.value !== null) {
                    params[element.name] = element.value;
                }
            });
            this.$Forms.getCollection({params}, 'main_forms_list').then((response) => {
                this.pages = response.data["hydra:totalItems"];
                this.rows = response.data["hydra:member"];
            });
        },
        setSort(offset, limit, order, sort) {
            this.sortable.order = order;
            this.sortable.sort = sort;
            if (order === "station") {
                this.sortable.order = order + ".name";
            }
            if (order === "customer") {
                this.sortable.order = order + ".customer";
            }

            this.load();
        },
        onChangePage(page) {
            this.currentPage = page;
            this.load();
        },
        deleteOcr(url) {
            this.deleteByUrl(
                this.$Ocrs,
                url,
                this.$t("messages.ocrDeleted"),
                null,
                this.load,
                this.error
            );
        },
        inputChange(value) {
            this.currentPage = 1;
            this.search = value;
            this.load();
        },
        onPageChange() {
            this.load();
        },
        technicalDelay(etd, timeOfCSRIssue) {
            let diff = new Date(etd) - new Date(timeOfCSRIssue);
            if (diff <= 0) {
                return this.$t("forms.noTechnicalDelay");
            }

            let hours = Math.floor(diff / 1000 / 60 / 60);
            let minutes = Math.floor((diff / 1000 / 60 / 60 - hours) * 60);

            return hours + " hours " + minutes + " minutes ";
        },
        setAdvancedParams(data) {
            this.advancedParams = data;
            this.load();
        },
        perPageChange(num) {
            this.currentPage = 1;
            this.perPage = num;
            this.load();
        },
        enabledChange(enabled) {
            this.enabled = enabled.id;
            if (enabled.id === "all") {
                this.enabled = [true, false];
            }
            this.load();
        },
        error() {
            this.$emit("clearAction");
        },
    },
};
</script>

<style lang="scss">
</style>
